import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { ISortItem } from '@root-gipro/shared/FilterHeader/components/sort-item.model'
import { unLoadWindow } from '@root-gipro/shared/FilterHeader/utils/unLoadWindow'
import { getIdentifier } from '@root-gipro/shared/FilterHeader/utils/getIdentifier'
import SortIcon from '@material-ui/icons/Sort'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'

const SortItems: React.FC<{
	sortItems(name: string, sortName: string, order?: boolean): void
	sortList: ISortItem[]
}> = ({ sortItems, sortList }) => {
	if (!sortList || !sortList) {
		throw Error('SortItems: not enough arguments')
	}
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
	const [orderSort, setOrderSort] = React.useState<boolean>(true)
	const [hasFetchedWithCompanyId, setHasFetchedWithCompanyId] = useState(false)

	const location = useLocation()
	const history = useHistory()
	const url: string = location.pathname.slice(1)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'common-projects'
			? filter.commonProjects
			: url === 'user-projects'
			? filter.userProjects
			: url === 'user-archive'
			? filter.userArchive
			: filter.userProjects

	const { showFilterState } = useSelector((state: IState) => currentFilter)

	const handleClickListItem = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			if (!event?.currentTarget) {
				return
			}
			setAnchorEl(event.currentTarget)
		},
		[anchorEl]
	)

	const handleMenuItemClick = useCallback(
		(event: React.MouseEvent<HTMLElement>, index: number) => {
			setSelectedIndex(index)
			setAnchorEl(null)
			sortItems(sortList[index].name, sortList[index].sortName, sortList[index].order)
		},
		[selectedIndex, orderSort]
	)

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [anchorEl])

	useEffect(() => {
		showFilterState.companyId && showFilterState.ipr_id
			? setHasFetchedWithCompanyId(true)
			: setHasFetchedWithCompanyId(false)
	}, [showFilterState.companyId, showFilterState.ipr_id])

	useEffect(() => {
		const indexIdentifier = getIdentifier(location, sortList)

		unLoadWindow(location, history) // убирает hash из url
		if (location.pathname === '/common-projects' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier].name, sortList[indexIdentifier].sortName, !orderSort)
			setOrderSort(false)
		}
		if (location.pathname === '/favorites-common-projects' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier].name, sortList[indexIdentifier].sortName, !orderSort)
			setOrderSort(false)
		}
		if (location.pathname === '/purchases' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier].sortName, orderSort)
		}
		if (location.pathname === '/favorites-purchases' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier].sortName, orderSort)
		}
		if (hasFetchedWithCompanyId) {
			if (location.pathname === '/user-projects' && indexIdentifier === 0 && !location.hash) {
				sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier].sortName, orderSort)
				setHasFetchedWithCompanyId(true)
			}
			if (location.pathname === '/user-archive' && indexIdentifier === 0 && !location.hash) {
				sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier].sortName, orderSort)
				setHasFetchedWithCompanyId(true)
			}
		}

		setSelectedIndex(indexIdentifier)
	}, [location.pathname, location.hash, hasFetchedWithCompanyId])

	return (
		<div className={`projects__sort`}>
			<div className='projects__sort-select'>
				<span className='projects__sort-select-option' onClick={handleClickListItem}>
					{selectedIndex === undefined ? (
						<SortIcon style={{ transform: sortList[0]?.order ? 'scale(1, -1)' : '' }} />
					) : (
						<SortIcon style={{ transform: sortList[selectedIndex]?.order ? 'scale(1, -1)' : '' }} />
					)}
					{selectedIndex === undefined ? sortList[0]?.name : sortList[selectedIndex]?.name}
				</span>
			</div>
			<Menu
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: -6,
					horizontal: 12,
				}}
				anchorEl={anchorEl}
				keepMounted
				getContentAnchorEl={null}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{sortList.map((option, index) => (
					<MenuItem
						key={index}
						selected={index === selectedIndex}
						onClick={event => handleMenuItemClick(event, index)}
						style={{ display: 'flex', gap: '8px' }}
					>
						<SortIcon style={{ transform: option.order ? 'scale(1, -1)' : '' }} />
						<span>{option.name}</span>
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}

export default SortItems
