import store from '@root-gipro/store'
import { fetchData } from '@root-gipro/store/api'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { PublicType } from '../actions/public'

function* fetchPublicInfo() {
	const userId = store.getState().auth.user.id

	const {
		policesGipro,
		policesRGCO,
		filial,
		groups,
		company,
		regions,
		publicationDates,
		uncThDescriptions,
		rgStatuses,
		userSettings,
	} = yield all({
		policesGipro: call(
			fetchData,
			'/policies?action=.userProject&kind=self',
			(res: any) => res.policies['.userProject']['self']
		),
		policesRGCO: call(
			fetchData,
			'/policies?action=rgco.ed.create&kind=all',
			(res: any) => res.policies['rgco.ed.create']['all']
		),
		filial: call(fetchData, '/filial', (res: any) => res.filial),
		groups: call(fetchData, '/groups', (res: any) => res.groups),
		company: call(fetchData, '/company', (res: any) => res.company),
		regions: call(fetchData, '/regions', (res: any) => res.regions),
		publicationDates: call(fetchData, '/projects/values/publicationDate', (res: any) => res.values),
		uncThDescriptions: call(fetchData, '/info/unc-text-parameters', (res: any) => res.uncTextParameters),
		rgStatuses: call(fetchData, '/rg-statuses', (res: any) => res.rgStatuses),
		userSettings: call(fetchData, `/user-settings?userId=${userId}`, (res: any) => res.userSettings[0], 'GET'),
	})

	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		policesGipro,
		policesRGCO,
		filial,
		groups,
		company,
		regions,
		publicationDates,
		uncThDescriptions,
		rgStatuses,
		userSettings,
	})
}

function* fetchPublicUserProjectInfo({
	unc_sort,
}: {
	type: typeof PublicType.FETCH_PUBLIC_USER_PROJECT_INFO
	unc_sort: string
}) {
	const { uncMains, uncSections, uncThs } = yield all({
		policesGipro: call(
			fetchData,
			'/policies?action=.userProject&kind=self',
			(res: any) => res.policies['.userProject']['self']
		),
		policesRGCO: call(
			fetchData,
			'/policies?action=rgco.ed.create&kind=all',
			(res: any) => res.policies['rgco.ed.create']['all']
		),

		uncMains: call(fetchData, `/uncMains?order=code`, (res: any) =>
			res.uncMains.filter((el: any) => Number(el.unc_sort) == Number(unc_sort))
		),
		uncSections: call(fetchData, `/unc-sections&unc_sort=${unc_sort}`, (res: any) => res.uncSections),
		uncThs: call(fetchData, `/info/unc-ths&unc_sort=${unc_sort}`, (res: any) => res.uncThs),
	})

	yield put({
		type: PublicType.SET_PUBLIC_USER_PROJECT_INFO,
		uncMains,
		uncSections,
		uncThs,
	})
}

// function* fetchPublicUncThDescriptions() {
// 	const { uncThDescriptions } = yield all({
// 		policesGipro: call(
// 			fetchData,
// 			'/policies?action=.userProject&kind=self',
// 			(res: any) => res.policies['.userProject']['self']
// 		),
// 		policesRGCO: call(
// 			fetchData,
// 			'/policies?action=rgco.ed.create&kind=all',
// 			(res: any) => res.policies['rgco.ed.create']['all']
// 		),
// 		uncThDescriptions: call(fetchData, '/info/unc-text-parameters', (res: any) => res.uncTextParameters),
// 	})

// 	yield put({
// 		type: PublicType.SET_PUBLIC_INFO,
// 		uncThDescriptions,
// 	})
// }

export default function* watchFetchPublicInfo() {
	yield takeLatest(PublicType.FETCH_PUBLIC_INFO, fetchPublicInfo)
	yield takeLatest(PublicType.FETCH_PUBLIC_USER_PROJECT_INFO, fetchPublicUserProjectInfo)
	// yield takeLatest(PublicType.FETCH_PUBLIC_UNC_DESCRIPTIONS, fetchPublicUncThDescriptions)
}
