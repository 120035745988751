import { makeStyles } from '@material-ui/core/styles'
import { ModalContext } from '@root-gipro/context/modal-context'
import { ProjectType } from '@root-gipro/modules/favorites/models/enums/projects.enum'
import { history } from '@root-gipro/router'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { TabsDropDown } from '@root-gipro/shared/FilterHeader/components/DropDown/TabsDropDown'
import { ISortItem } from '@root-gipro/shared/FilterHeader/components/sort-item.model'
import SortItems from '@root-gipro/shared/FilterHeader/components/sort-items'
import '@root-gipro/shared/FilterHeader/styles/FilterHeader.scss'
import PrikazLinks from '@root-gipro/shared/PrikazLinks/PrikazLinks'
import { FilterSVG } from '@root-gipro/shared/SVGComponents/FilterSVG'
import { TabsToggle } from '@root-gipro/shared/TabsComponents/TabsToggle'
import TabUncProjects from '@root-gipro/shared/TabUnc/TabUncProjects'
import { IState } from '@root-gipro/store/interfaces'
import { lightPretty, prettyCost } from '@root-gipro/utils/helpers/common'
import React, { FC, MouseEvent, memo, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { ButtonStyle, ModalFavorites, Title, WarningBlockDiv, WrapperButton } from './styles'

const useStyles = makeStyles({
	projectsHeadSortText: {
		color: 'rgba(0, 0, 0, 0.56)',
		fontSize: '14px',
		marginRight: 8,
	},
})

export interface IFilterHead {
	editToggleType(toggleType: 'offer' | 'plan' | 'notice' | ProjectType.PROJECTS | ProjectType.PURCHASES): void
	toggleType: 'offer' | 'plan' | 'notice' | ProjectType.PROJECTS | ProjectType.PURCHASES
	projectsCount: number
	offerSum?: number
	planSum: number
	sort?(name: string, sortName: string, order?: boolean): void
	sortList: ISortItem[]
	types: types[]
	isShowFilter(): void
	handleFavorites?(event: MouseEvent<HTMLDivElement>): void
	isFavorite: boolean
	favoritesButtonDelete?: React.ReactNode | any
}
export type types = {
	title: string
	value: string
}

const FilterHeaderContainer: FC<IFilterHead> = memo(
	({ toggleType, editToggleType, projectsCount, offerSum, planSum, sort, sortList, types, isShowFilter }) => {
		const [isModal, setIsModal] = useState(false)
		const { isFilterOpen } = useSelector((state: IState) => state.maket)
		const { favoritesList } = useSelector((state: IState) => state.favorites)
		const { groupId, regionId, code, name } = useSelector((state: IState) => state.filter.appliedFilters)
		const { setAnchorEl, anchorEl } = useContext(ModalContext)
		const classes = useStyles()
		const [showUncSortInfo, setShowUncSortInfo] = useState(false)

		const routeMatch = useRouteMatch()
		const typePageUrl: string = routeMatch.path.split('/')[1]
		const { projects } = useSelector((state: IState) => state.commonProjects)

		const handleClick = useCallback(
			(event: MouseEvent<HTMLElement>) => {
				setAnchorEl!(anchorEl ? null! : event.currentTarget)
			},
			[anchorEl]
		)

		const [currentTab, setCurrentTab] = useState<string | null>(null)

		useEffect(() => {
			if (projects && projects.length) setCurrentTab(projects[0]?.unc_sort ?? null)
		}, [projects])

		useEffect(() => {
			const conditions = [
				Array.isArray(groupId) && groupId.length === 1,
				Array.isArray(regionId) && regionId.length === 1,
				Boolean(code),
				Boolean(name),
			]

			const trueConditionsCount = conditions.reduce((count, condition) => count + (condition ? 1 : 0), 0)

			setShowUncSortInfo(prevState => {
				const newState = trueConditionsCount === 1
				return prevState !== newState ? newState : prevState
			})
		}, [groupId, regionId, code, name, setShowUncSortInfo])

		const onCancel = () => {
			setIsModal(false)
		}
		const onDeleteFavorites = () => {
			setIsModal(false)
		}

		return (
			<>
				<div className='projects__head'>
					<div className='projects__head__left'>
						{history.location.pathname !== '/favorites' && (
							<SecondaryBtn
								onClick={isShowFilter}
								className={`projects__head__filter-btn  ${isFilterOpen && 'active'} `}
							>
								<FilterSVG />
								Фильтр
							</SecondaryBtn>
						)}
						{sort && <span className={classes.projectsHeadSortText}>Сортировка по:</span>}
						{sort && <SortItems sortItems={sort} sortList={sortList} />}
					</div>

					<div className='projects__head__right'>
						{(history.location.pathname === '/favorites-common-projects' ||
							history.location.pathname === '/favorites-purchases') &&
							favoritesList?.length > 0}
						<div className='projects__head-info ml-8'>
							{toggleType !== 'plan' && (
								<div className='desktop-tabs '>
									<TabsToggle
										types={types}
										editToggleType={(
											value: 'offer' | 'plan' | 'notice' | ProjectType.PROJECTS | ProjectType.PURCHASES
										) => editToggleType(value)}
										toggleType={toggleType}
									/>
								</div>
							)}

							<div className='mobile-tabs' data-popup='korr' onClick={handleClick}>
								<SecondaryBtn className='min-w-32'>К</SecondaryBtn>

								{anchorEl?.dataset.popup === 'korr' && (
									<TabsDropDown
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl!}
										types={types}
										editToggleType={editToggleType}
										toggleType={toggleType}
									/>
								)}
							</div>

							{showUncSortInfo ? (
								<div>
									<TabUncProjects currentTab={currentTab} />
								</div>
							) : null}

							<div className='projects__head-info-fix-width'>
								<div className='projects__head__total-count'>
									<span>Всего: </span>
									{lightPretty(String(projectsCount))}
								</div>
								<div className='projects__head-sum'>
									{prettyCost(toggleType === 'plan' ? planSum : offerSum || planSum)} млн.р
								</div>
							</div>
						</div>
					</div>

					{isModal && (
						<ModalFavorites
							onClick={e => {
								e.preventDefault()
								e.stopPropagation()
								setIsModal(false)
							}}
						>
							<WarningBlockDiv
								onClick={e => {
									e.stopPropagation()
								}}
							>
								<Title>{`Подтвердите, действительно ли вы хотите удалить все ${
									toggleType === 'PROJECTS' ? 'ИПР' : 'Закупки'
								} из избранного?`}</Title>
								<Title>{`Все ${toggleType === 'PROJECTS' ? 'ИПР' : 'Закупки'} будут удалены.`}</Title>
								<WrapperButton>
									<ButtonStyle onClick={onDeleteFavorites} title='Подтверждаю'>
										Подтверждаю
									</ButtonStyle>
									<ButtonStyle type='cancel' onClick={onCancel}>
										Отмена
									</ButtonStyle>
								</WrapperButton>
							</WarningBlockDiv>
						</ModalFavorites>
					)}
				</div>
				{currentTab && showUncSortInfo ? (
					<div className='projects__head-footer'>
						<PrikazLinks uncSort={Number(currentTab)} url={typePageUrl} />
					</div>
				) : null}
			</>
		)
	}
)

export default FilterHeaderContainer
