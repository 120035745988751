import {
	Box,
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
	MenuItem,
	Popover,
	Theme,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import { CompanyIPR } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { IState } from '@root-gipro/store/interfaces'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface IAdminPanelCreateUserCompany {
	updateUserIpr: (iprs: CompanyIPR[]) => void
	defaultIpr: CompanyIPR[]
	userCompanyId: number
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popover: {
			pointerEvents: 'none',
		},
		paper: {
			padding: theme.spacing(1),
		},
	})
)

const AdminPanelSelectIpr: FC<IAdminPanelCreateUserCompany> = ({ updateUserIpr, defaultIpr, userCompanyId }) => {
	const classes = useStyles()
	const roleId = localStorage.getItem('roleId')
	const [value, setValue] = useState<CompanyIPR[] | []>(Array.isArray(defaultIpr) ? defaultIpr : defaultIpr ? [] : [])
	const [open, setOpen] = useState(false)
	const [disabledLinkBtn, setDisabledLinkBtn] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

	let { company } = useSelector((state: IState) => state.public)
	company = [...company].sort((a, b) => {
		if (a.name < b.name) return -1
		if (a.name > b.name) return 1
		return 0
	})

	const handleChange = (iprs: number) => {
		setValue((prev: CompanyIPR[]) => {
			const exists = prev.some(ipr => ipr.id === iprs)
			if (exists) {
				return prev.filter(ipr => ipr.id !== iprs)
			} else {
				const updatedCompany: CompanyIPR[] = company?.map(companyObj => ({
					...companyObj,
					ipr: companyObj.ipr.filter((ipr: any) => ipr.id !== iprs),
				}))
				const newIpr = updatedCompany.find(ipr => ipr.id === iprs)
				return newIpr ? [...prev, newIpr] : prev
			}
		})
	}

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (disabledLinkBtn) setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const openPopover = Boolean(anchorEl)

	const handleSubmit = () => {
		const ids: number[] = []

		// if (value && Array.isArray(value) && value.length > 0) {
		// 	value.forEach(item => ids.push(Number(item.id)))
		// }

		updateUserIpr(value)
	}
	const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
		if (reason !== 'backdropClick') {
			setOpen(false)
		}
	}

	useEffect(() => {
		if (Array.isArray(defaultIpr)) {
			const intersection = defaultIpr.filter(company => company.id === userCompanyId).length

			const isDisabled =
				(defaultIpr.length <= 0 && Number(roleId) !== 3) ||
				(intersection && defaultIpr.length === 1 && Number(roleId) !== 3)

			setDisabledLinkBtn(Boolean(isDisabled))
		} else {
			setDisabledLinkBtn(true)
		}
	}, [defaultIpr])

	return (
		<div>
			<div
				aria-owns={openPopover ? 'mouse-over-popover' : undefined}
				aria-haspopup='true'
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<IconButton type='button' onClick={handleClickOpen} disabled={disabledLinkBtn}>
					<LinkIcon />
				</IconButton>
			</div>

			<Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
				<DialogTitle>Выберите компанию</DialogTitle>
				<DialogContent>
					<Box component='form'>
						{Number(roleId) === 3
							? company?.map((company, index) => (
									<MenuItem
										style={{
											background: value && value?.some(item => item?.id === company.id) ? '#c3c3c3' : 'white',
										}}
										value={company.id}
										key={company.id}
										onClick={() => {
											handleChange(Number(company.id))
										}}
									>
										<span>{`${index + 1}.`}&nbsp; </span>
										{`${company.name} ${company.inn ? `, ИНН: ${company.inn}` : ''}`}
									</MenuItem>
							  ))
							: Array.isArray(defaultIpr)
							? //@ts-ignore
							  defaultIpr?.map((company, index) => {
									if (company.id === userCompanyId) return
									else
										return (
											<MenuItem
												disableRipple
												style={{
													background: 'white',
													cursor: 'initial',
												}}
												value={company.id}
												key={company.id}
												onClick={e => {
													e.preventDefault()
													e.stopPropagation()
												}}
											>
												<span>{`${index + 1}.`}&nbsp; </span>
												{`${company.name} ${company.inn ? `, ИНН: ${company.inn}` : ''}`}
											</MenuItem>
										)
							  })
							: null}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Назад</Button>
					<Button onClick={handleSubmit}>Принять</Button>
				</DialogActions>
			</Dialog>
			<Popover
				id='mouse-over-popover'
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={openPopover}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				Нет связанных ИПР
			</Popover>
		</div>
	)
}

export default AdminPanelSelectIpr
