import { HelpOutline } from '@material-ui/icons'
import React from 'react'

const helpMessage =
	'В системе имеется функция хранения и расчета проектов по расценкам, которые предшествуют актуальному сборнику УНЦ, с целью контроля конфигурации и стоимости исторических расчетов и их оперативной конвертации в актуальные расценки новой версии проекта'

interface PrikazLinksProps {
	uncSort: number
	url: string
}

const PrikazLinks: React.FC<PrikazLinksProps> = ({ uncSort, url }) => {
	const showHelpMessage = url === 'user-projects'

	return (
		<>
			{uncSort === 2024 ? (
				<a
					className='link-blue link-underline'
					download={'Приказ МЭ № 131 от 26.02.2024.pdf'}
					target='_blank'
					href='/assets/prikaz131.pdf'
					rel='noopener noreferrer'
				>
					Приказ Минэнерго № 131 от 26.02.2024
				</a>
			) : (
				<a
					className='link-blue link-underline'
					href='/assets/prikaz10.pdf'
					download={'Приказ МЭ № 10 от 17.01.2019.pdf'}
				>
					Приказ Минэнерго от 17.01.2019 №10 (не действ.)
					{showHelpMessage && <HelpOutline titleAccess={helpMessage} />}
				</a>
			)}
		</>
	)
}

export default PrikazLinks
