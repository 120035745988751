import { AdminPanelType } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import { CompanyIPR, IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import {
	getCompanyGroupsFetch,
	getUsersFetch,
	loadingUsers,
	setCompanies,
	setUser,
	setUsers,
} from '@root-gipro/modules/AdminPanel/store/actions'
import { getUserAuthRole, getUserRole } from '@root-gipro/modules/AdminPanel/store/helpers'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { setLoadingStates, setProgressPrecent } from '@root-gipro/modules/userProjects/store/actions'
import store from '@root-gipro/store'
import { setCompanyList } from '@root-gipro/store/actions'
import { fetchAuthHeaders, fetchData, fetchHeaders } from '@root-gipro/store/api'
import { authorizeApi } from '@root-gipro/store/api/index'
import { ICompany } from '@root-gipro/store/interfaces'
import { call, put, takeEvery } from 'redux-saga/effects'

function* getAuthToken() {
	try {
		const authorize: any = yield call(authorizeApi, 'auth')
		if (authorize && authorize.status === 'success') {
			yield localStorage.setItem('service_auth_token', authorize.access_token)
			yield put(getUsersFetch())
		}
	} catch (error) {
		console.log(error)
	}
}

function* getUsers() {
	try {
		yield put(loadingUsers(true))
		let users: any = []

		yield fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users`, {
			method: 'GET',
			headers: fetchAuthHeaders(),
		})
			.then(res => res.json())
			.then(data => data.users)
			.then(data => {
				users = data
			})

		yield put(setUsers(users))
		yield put(loadingUsers(false))
	} catch (error) {
		console.log(error)
		yield put(loadingUsers(false))
	}
}

function* setUserRoleFetch({
	userId,
	role,
}: {
	type: typeof AdminPanelType.SET_USER_ROLE_FETCH
	userId: number
	role: number
}) {
	try {
		const userRole: any = yield getUserRole(userId)
		const userRoleAuth = yield getUserAuthRole(userId)

		const switchRole = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/userRoles/${userRole}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify({ roleId: role }),
			})
			const data = await req.json()
			return data
		}
		const switchRoleAuth = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/userRoles/${userRoleAuth}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify({ roleId: role }),
			})
			const data = await req.json()
			return data
		}

		yield call(switchRole)
		yield call(switchRoleAuth)
		yield put(getUsersFetch())
	} catch (error) {
		console.log(error)
	}
}
function getErrorMessages(errors: any) {
	const messages: [] = []

	function extractMessages(errorObject: []) {
		for (const key in errorObject) {
			if (typeof errorObject[key] === 'object') {
				extractMessages(errorObject[key])
			} else {
				messages.push(errorObject[key])
			}
		}
	}

	extractMessages(errors)
	return messages
}

function* createUser({ user }: { type: typeof AdminPanelType.CREATE_USER; user: IUser }) {
	try {
		const reqApi = async () => {
			const res = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users`, {
				method: 'POST',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(user),
			})
			if (res.status === 200 && res.ok) {
				const data = await res.json()
				return data
			} else {
				const data = await res.json()
				if (data.errors) {
					const errorMessages = getErrorMessages(data.errors)
					store.dispatch(
						showNotify({
							type: 'error',
							message: errorMessages.join(', '),
						})
					)
					throw Error(errorMessages.join(', '))
				}
			}
		}
		yield call(reqApi)
		yield put(getUsersFetch())
	} catch (error) {
		console.error(error)
	}
}

function* checkUserInfoFetch({ id }: { type: typeof AdminPanelType.CHECK_USER_INFO; id: number }) {
	try {
		const getUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'GET',
				headers: fetchAuthHeaders(),
			})
			const data = await req.json()
			return data.user
		}

		const user = yield call(getUser)
		yield put(setUser(user))
	} catch (error) {
		console.log(error)
	}
}

function* updateUserInfo({
	id,
	user,
	date_start,
	date_end,
	access,
	idUserAccess,
}: {
	type: typeof AdminPanelType.UPDATE_USER_INFO_FETCH
	id: number
	user: IUser
	date_start: number
	date_end: number
	access: boolean
	idUserAccess?: number | null
}) {
	const { date_start: start_date, date_end: end_date, access: access_user, ...clearUser } = user
	const userChangeInfo = {
		date_start: date_start,
		date_end: date_end,
		access: access,
		...clearUser,
	}
	try {
		const updateUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(userChangeInfo),
			})
			if (req.status === 200 && req.ok) {
				const data = await req.json()
				return data.user
			} else {
				const data = await req.json()
				if (data.errors) {
					const errorMessages = getErrorMessages(data.errors)
					store.dispatch(
						showNotify({
							type: 'error',
							message: errorMessages.join(', '),
						})
					)
					throw Error(errorMessages.join(', '))
				}
			}
		}
		yield call(updateUser)
		yield put(getUsersFetch())
	} catch (error) {
		console.error(error)
	}
}

function* updateUserIpr({
	id,
	user,
	iprIds,
}: {
	type: typeof AdminPanelType.UPDATE_USER_INFO_FETCH
	id: number
	user: IUser
	iprIds: CompanyIPR[]
}) {
	const { verifiedEmail, deviceLimit, roleId, id: userId, company_ipr, ...clearUser } = user
	const userChangeInfo = {
		company_ipr: iprIds,
		password: null,
		date_start: user.access?.date_start,
		date_end: user.access?.date_end,
		...clearUser,
	}
	try {
		const updateUser = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'PATCH',
				headers: fetchAuthHeaders(),
				body: JSON.stringify(userChangeInfo),
			})
			const data = await req.json()
			return data.user
		}
		yield call(updateUser)
		yield put(getUsersFetch())
	} catch (error) {
		console.error(error)
	}
}

function* deleteUser({ id }: { type: typeof AdminPanelType.DELETE_USER; id: number }) {
	try {
		const deleteUserFetch = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}`, {
				method: 'DELETE',
				headers: fetchAuthHeaders(),
			})
			if (req.status === 200 && req.ok) {
				const data = await req.json()
				return data.user
			} else {
				const data = await req.json()
				if (data.errors) {
					const errorMessages = getErrorMessages(data.errors)
					store.dispatch(
						showNotify({
							type: 'error',
							message: errorMessages.join(', '),
						})
					)
					throw Error(errorMessages.join(', '))
				}
			}
		}

		yield call(deleteUserFetch)
		yield put(getUsersFetch())
		yield put(
			showNotify({
				type: 'success',
				message: `Пользователь успешно удален`,
			})
		)
	} catch (error) {
		console.error(error)
	}
}

function* fetchCompanyGroupsInfo() {
	try {
		const company: ICompany[] = yield call(fetchData, '/company', (res: any) => res.company)
		yield put(setCompanies(company))
		yield put(setCompanyList(company))
	} catch (error) {
		console.log(error)
	}
}

function* createCompany({ company }: { type: typeof AdminPanelType.CREATE_COMPANY; company: any }) {
	try {
		const reqApi = async () => {
			const { id, ...compObj } = company

			const comp = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/company`, {
				method: 'POST',
				headers: fetchHeaders(),
				body: JSON.stringify(compObj),
			})

			const dataComp = await comp.json()
			return dataComp
		}
		yield call(reqApi)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

function* updateCompanyIpr({ ipr }: { type: typeof AdminPanelType.UPDATE_COMPANY_IPR; ipr: any }) {
	try {
		const { id, ...iprObj } = ipr
		const updateCompanyIpr = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr/${id}`, {
				method: 'PATCH',
				headers: fetchHeaders(),
				body: JSON.stringify({ ...iprObj, is_active: iprObj.is_active == 1 ? true : false }),
			})
			const data = await req.json()
			return data
		}
		yield call(updateCompanyIpr)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}
function* createCompanyIpr({ ipr }: { type: typeof AdminPanelType.CREATE_COMPANY_IPR; ipr: any }) {
	try {
		const reqApi = async () => {
			const comp = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr`, {
				method: 'POST',
				headers: fetchHeaders(),
				body: JSON.stringify(ipr),
			})

			const dataComp = await comp.json()
			return dataComp
		}
		yield call(reqApi)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

function* createCopyVersionIpr({ id }: { type: typeof AdminPanelType.CREATE_COPY_VERSION_IPR; id: any }) {
	try {
		// Функция для запроса
		const reqApi = () =>
			fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr?fromIprId=${id}`, {
				method: 'POST',
				headers: fetchHeaders(),
			})

		const response = yield call(reqApi)

		if (!response.ok) {
			yield put(
				showNotify({
					type: 'error',
					message: 'Ошибка копирования версии, попробуйте позже.',
				})
			)
			return
		}

		// Потоковое чтение данных
		const reader = response.body?.getReader()
		const decoder = new TextDecoder('utf-8')
		let buffer = ''
		let finalJsonBuffer = ''

		while (true) {
			const { done, value } = yield call([reader, 'read'])

			buffer += decoder.decode(value || new Uint8Array(), { stream: true })
			let boundary = buffer.indexOf('\n')

			while (boundary !== -1) {
				const chunk = buffer.slice(0, boundary).trim()
				buffer = buffer.slice(boundary + 1)

				if (chunk.startsWith('data:')) {
					const progressPercent = parseInt(chunk.replace('data:', '').trim())
					if (!isNaN(progressPercent)) {
						yield put(setProgressPrecent(progressPercent)) // Обновляем прогресс
					}
				} else {
					finalJsonBuffer = chunk
				}

				boundary = buffer.indexOf('\n')
			}

			// Если поток завершён
			if (done) {
				if (buffer.trim()) {
					finalJsonBuffer = buffer.trim()
				}
				if (finalJsonBuffer) {
					const parsedData = JSON.parse(finalJsonBuffer)
					if (parsedData.status === 'failure') {
						yield put(
							showNotify({
								type: 'error',
								message: `Ошибка: ${parsedData.message}`,
							})
						)
					} else {
						yield put(getCompanyGroupsFetch()) // Успешное завершение
						yield put(
							showNotify({
								type: 'success',
								message: `Копирование завершено`,
							})
						)
					}
				} else {
					yield put(
						showNotify({
							type: 'error',
							message: 'Ошибка копирования версии, попробуйте позже.',
						})
					)
				}
				break
			}
		}

		yield put(setProgressPrecent(0)) // Сброс прогресса
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	} finally {
		yield put(setLoadingStates('', false))
	}
}

function* deleteCompanyIpr({ id }: { type: typeof AdminPanelType.DELETE_COMPANY_IPR; id: number }) {
	try {
		const reqApi = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/ipr/${id}`, {
				method: 'DELETE',
				headers: fetchHeaders(),
			})
			const data = await req.json()
			return data
		}

		const res = yield call(reqApi)
		if (res.status == 'success') {
			yield put(getCompanyGroupsFetch())
			yield put(
				showNotify({
					type: 'success',
					message: `Версия ИПР успешно удалена`,
				})
			)
		} else {
			throw Error(res.message)
		}
	} catch (error) {
		console.log(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error.message}`,
			})
		)
	}
}

function* updateCompanyInfo({
	company,
}: {
	type: typeof AdminPanelType.UPDATE_COMPANY
	id: number | string
	company: any
}) {
	try {
		const { id, ...compObj } = company
		const updateCompany = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/company/${id}`, {
				method: 'PATCH',
				headers: fetchHeaders(),
				body: JSON.stringify(compObj),
			})
			const data = await req.json()
			return data
		}
		yield call(updateCompany)
		yield put(getCompanyGroupsFetch())
	} catch (error) {
		console.error(error)
		yield put(
			showNotify({
				type: 'error',
				message: `${error}`,
			})
		)
	}
}

export default function* adminPanel() {
	yield takeEvery(AdminPanelType.GET_AUTH_USER_TOKEN, getAuthToken)
	yield takeEvery(AdminPanelType.GET_USERS_FETCH, getUsers)
	yield takeEvery(AdminPanelType.CHECK_USER_INFO, checkUserInfoFetch)
	yield takeEvery(AdminPanelType.UPDATE_USER_INFO_FETCH, updateUserInfo)
	yield takeEvery(AdminPanelType.UPDATE_USER_IPRS, updateUserIpr)
	yield takeEvery(AdminPanelType.DELETE_USER, deleteUser)
	// yield takeEvery(AdminPanelType.GET_USER_ROLE_FETCH, getUserRole)
	yield takeEvery(AdminPanelType.CREATE_USER, createUser)
	yield takeEvery(AdminPanelType.SET_USER_ROLE_FETCH, setUserRoleFetch)
	yield takeEvery(AdminPanelType.UPDATE_COMPANY, updateCompanyInfo)
	yield takeEvery(AdminPanelType.CREATE_COMPANY, createCompany)
	yield takeEvery(AdminPanelType.GET_COMPANY_FETCH, fetchCompanyGroupsInfo)
	yield takeEvery(AdminPanelType.CREATE_COMPANY_IPR, createCompanyIpr)
	yield takeEvery(AdminPanelType.DELETE_COMPANY_IPR, deleteCompanyIpr)
	yield takeEvery(AdminPanelType.UPDATE_COMPANY_IPR, updateCompanyIpr)
	yield takeEvery(AdminPanelType.CREATE_COPY_VERSION_IPR, createCopyVersionIpr)
}
