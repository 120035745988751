import { Tooltip } from '@material-ui/core'
import {
	ICommonProject,
	Notices,
	Plans,
} from '@root-gipro/modules/commonProjects/models/interfaces/common-projects.model'
import React from 'react'
import { Link } from 'react-router-dom'
import { History } from 'history'

export interface IBodyParamsCommonProjects {
	textAlign: string;
	value: (project: Notices | Plans | ICommonProject, index: number) => JSX.Element;
}

export const bodyParams = (history: History) => [
	{
		value: (project: Notices | Plans | ICommonProject, index: number): JSX.Element =>
			(<span>{index + 1}.</span>) as JSX.Element,
		textAlign: 'textAlignLeft',
	},
	{
		value: (project: Notices | Plans | ICommonProject, index: number): JSX.Element =>
			(
				<Link
					to={{
						pathname: `/purchases/${(project as Notices | Plans).model}/${project.id}`,
						state: {
							from: history.location.pathname,
							fromName: 'Инвестиционные ИПР',
							previousState: {
								isOpenPopover: true,
							},
						},
					}}
					className='project__code link link-blue'
				>
					{(project as Notices | Plans).registrationNumber}
				</Link>
			) as JSX.Element,
		textAlign: 'textAlignLeft',
	},
	{
		value: (project: Notices | Plans, index: number): JSX.Element => (
			<Tooltip title={project.subject} placement='top'>
				<span>{project.subject}</span>
			</Tooltip>
		),
		textAlign: 'textAlignLeft',
	},
]
