import { Checkbox, ListItemText, Radio, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import '@root-gipro/shared/FormElements/Select/Select.scss'
import { IState } from '@root-gipro/store/interfaces'
import { isEqual } from 'lodash'
import React, { ChangeEvent, ReactText, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
	option: {
		position: 'relative',
		height: 32,
	},
	input: {
		'& .MuiAutocomplete-input': {
			margin: '0',
		},
	},
})
export interface Props {
	value: string[]
	setValue(fieldValue: string, value: ReactText | string[]): void
	name?: string
	fieldName: string
	items: string[]
	placeholder?: string
	isDisable?: boolean
	withCheckbox?: {
		setChecked(value: boolean): void
		checked: boolean
		label: string
		disabled?: boolean
		checkboxChange?(): void
	}
	isMultiple?: boolean
	convertValue?(value: string): string
	disableCloseOnSelect?: boolean
}

const Select: React.FC<Props> = ({
	value,
	setValue,
	name,
	placeholder,
	isDisable,
	fieldName,
	items,
	withCheckbox,
	isMultiple,
	disableCloseOnSelect = true,
}) => {
	const [open, setOpen] = useState<boolean>(false)
	const [selectValue, setSelectValue] = useState<string | string[] | null>([])
	const classes = useStyles()

	const handleSelect = (event: ChangeEvent<{ value?: string | undefined }>, value: string | string[] | null) => {
		setValue(fieldName, value as ReactText | string[])
		if (!isMultiple) {
			setSelectValue(event.target.value ? event.target.value : value || [])
		}
		if (!isEqual(value, selectValue)) {
			setSelectValue(value || [])
		}
	}

	useEffect(() => {
		if (!isEqual(value, selectValue)) {
			setSelectValue(value || [])
		}
	}, [value])
	useEffect(() => {
		if (fieldName == 'ipr_id') {
			if (!selectValue?.length) {
				setSelectValue(value)
			}
		}
	}, [selectValue, value])

	return (
		<>
			{name && <h4 className='universal-select-title'>{name}</h4>}
			{placeholder && <label className='universal-select-label'>{placeholder}</label>}

			<Autocomplete
				className={classes.option + ' top-inherit'}
				multiple={isMultiple}
				options={items}
				value={selectValue}
				disableCloseOnSelect={disableCloseOnSelect}
				clearOnEscape
				clearText='Очистить'
				openText='Открыть'
				disabled={isDisable || withCheckbox?.checked}
				includeInputInList={false}
				closeIcon={<span className='close-icon' />}
				popupIcon={<span className='dropdown-icon' />}
				noOptionsText='Нет значений'
				closeText='Закрыть'
				openOnFocus
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				onChange={handleSelect}
				onBlur={(event: ChangeEvent<{ value?: string | undefined }>) => handleSelect(event, value)}
				filterOptions={(options, { inputValue }) => {
					if (isMultiple) {
						return Array.from(new Set([...items, ...options])).filter(value => {
							return value && value.toLowerCase().includes(inputValue?.toLowerCase())
						})
					} else {
						return Array.from(new Set([...items, ...options])).filter(value => {
							return value && value.toLowerCase().includes(inputValue.toLowerCase())
						})
					}
				}}
				renderTags={(value: string[]) => (
					<div className={isMultiple && open ? 'select-filter__multiple transparent-text' : 'select-filter__multiple'}>
						{value.join(', ')}
					</div>
				)}
				renderOption={(date, { selected }) =>
					isMultiple ? (
						<>
							{selected && <span className='checked-icon checked-icon-position' />}
							{date}
						</>
					) : (
						<>
							<Radio checked={selected} style={{ marginRight: 8 }} color='primary' />
							<span>{date}</span>
						</>
					)
				}
				renderInput={params => (
					<TextField
						type='text'
						variant='outlined'
						{...params}
						placeholder={fieldName == 'filialId' ? (!selectValue?.length ? 'Все филиалы' : '') : ''}
						className={classes.input}
					/>
				)}
			/>
			{withCheckbox && (
				<div className='aside-filter__checkbox'>
					<Checkbox checked={withCheckbox?.checked} onChange={() => withCheckbox?.setChecked(!withCheckbox?.checked)} />
					<ListItemText primary={withCheckbox?.label} />
				</div>
			)}
		</>
	)
}
export default Select
