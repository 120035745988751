import React, { FC, useState } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { Radio } from '@material-ui/core'
import styled from 'styled-components'
export interface SelectF20Props {
	title?: string
	handler1: (version: string) => void
	handler2: () => void
	setAnchorEl(event: HTMLElement): void
	anchorEl: (EventTarget & HTMLElement) | null | undefined
}
const ContainerRadio = styled.div`
	margin: 8px 0;
`
const NameRadio = styled.span`
	margin-left: 8px;
	font-size: 14px;
	vertical-align: middle;
	cursor: pointer;
`
const SelectF20Window: FC<SelectF20Props> = ({ anchorEl, handler1, handler2, setAnchorEl }) => {
	const [formVersion, setFormVersion] = useState('new')

	const buttons = {
		PrimaryBtn: {
			handler: () => handler1(formVersion),
			value: 'Скачать',
		},

		SecondaryBtn: {
			handler: handler2,
			value: 'Отменить',
		},
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Скачать форму 20',
			action: undefined,
		},
	]
	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
			<div>Выберите версию</div>
			<ContainerRadio>
				<Radio
					checked={formVersion === 'new'}
					value='Форма 20 (УНЦ-2024)'
					onChange={() => setFormVersion('new')}
					color='primary'
				/>
				<NameRadio onClick={e => setFormVersion('new')}>Форма 20 (УНЦ-2024)</NameRadio>
			</ContainerRadio>
			<ContainerRadio>
				<Radio
					checked={formVersion === 'newPlus'}
					value='Форма 20 (УНЦ-2024) обновленная'
					onChange={() => setFormVersion('newPlus')}
					color='primary'
				/>
				<NameRadio onClick={() => setFormVersion('newPlus')}>Форма 20 (УНЦ-2024) обновленная</NameRadio>
			</ContainerRadio>
		</Modal>
	)
}
export default SelectF20Window
