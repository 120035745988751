import {
	createStyles,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { ROLE_DEVELOPER, ROLE_OWNER } from '@root-gipro/modules/AdminPanel/store/helpers'
import { ICompany } from '@root-gipro/store/interfaces'
import React, { FC } from 'react'
import AdminPanelTableCompanyRow from './AdminPanelTableCompanyRow'

interface IAdminCompaniesTable {
	companies: ICompany[]
	searchText: string
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiTableCell-root': {
				padding: '6px 12px',
			},
		},
		tableContainer: {
			width: '100%',
			overflowX: 'auto',
		},
		table: {
			minWidth: 1800,
		},
	})
)

const AdminCompaniesTable: FC<IAdminCompaniesTable> = ({ companies, searchText }) => {
	const roleId = Number(localStorage.getItem('roleId'))
	const companyId = localStorage.getItem('companyId')
	const classes = useStyles()

	const filteredCompanies = companies
		.filter(company => {
			if (roleId > ROLE_DEVELOPER) {
				return Number(company.id) === Number(companyId)
			}
			return true
		})
		.filter(
			company =>
				company.name.toLowerCase().includes(searchText.toLowerCase()) ||
				company.inn.toLowerCase().includes(searchText.toLowerCase())
		)

	return (
		<TableContainer component={Paper} className={classes.tableContainer}>
			<Table className={classes.table} size='small' stickyHeader aria-label='company table'>
				<TableHead>
					<TableRow>
						{roleId <= ROLE_OWNER && (
							<TableCell align='center' style={{ width: 100 }}>
								Действия
							</TableCell>
						)}
						<TableCell style={{ width: 70 }}>№</TableCell>
						<TableCell>Наименование</TableCell>
						<TableCell>Версии ИПР</TableCell>
						<TableCell>Полное название</TableCell>
						<TableCell style={{ width: 100 }}>Точность</TableCell>
						<TableCell>Период ИПР</TableCell>
						<TableCell style={{ width: 180 }}>ИНН</TableCell>
						<TableCell style={{ minWidth: 180 }}>КПП</TableCell>
						<TableCell>ОГРН</TableCell>
						<TableCell>ОКАТО</TableCell>
						<TableCell>Регионы</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredCompanies.length ? (
						filteredCompanies
							.sort((a, b) => Number(a.id) - Number(b.id))
							.map((company, index) => <AdminPanelTableCompanyRow company={company} index={index} key={company.id} />)
					) : (
						<TableRow>
							<TableCell colSpan={10} align='left'>
								Нет данных для отображения
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default AdminCompaniesTable
