import {
	setAllowMoreProjects,
	setCommonProjectsCount,
	setCommonProjectsPaginationCount,
	setCommonProjectsSum,
	setFilterIdsCommonProjects,
	setUncInfo,
} from '@root-gipro/modules/commonProjects/store/actions'
import { FilterActions } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import {
	appliedFilters,
	filtersRequest,
	setFilterResultCount,
	showBtnCopyProjects2Calc,
} from '@root-gipro/modules/filter/store/actions'
import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import store from '@root-gipro/store'
import { setPublicUncTechnicalSpecifications } from '@root-gipro/store/actions/public'
import { fetchHeaders } from '@root-gipro/store/api'
import { ICommonProject, IUncInfo } from '@root-gipro/store/interfaces'
import { call, debounce, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { setUserProjectsArchive } from '../../userArchive/store/actions'
import { setAllowMoreUserProjects, setUserProjects } from '../../userProjects/store/actions'

function* refreshFilterCountResult(action: any) {
	const state = store.getState().filter

	const currentFilter =
		action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'favorites-common-projects'
			? state.commonProjectsFavorite
			: action.url === 'user-projects'
			? state.userProjects
			: action.url === 'user-archive'
			? state.userArchive
			: state.userProjects

	const object = excludeData(currentFilter.showFilterState)
	const params = {
		...object,
		...currentFilter.sortState,
	}

	if (state.showFilterResult) {
		const res = yield call(state.showFilterResult, params)
		// надо решить вопрос с обработкой запроса при одновременном указании параметров строительные ресурсы и технические решения! Пока такая заплатка
		if (res) {
			yield put(setPublicUncTechnicalSpecifications(res.uncTechnicalSpecifications)) // для обновления массива uncTechnicalSpecifications при сбросе фильтра
			yield put(setFilterResultCount(res.resultsCnt, action.url))

			if (action.url === 'user-projects' || action.url === 'user-archive') {
				yield put(setFilterResultCount(res.userProjectsCnt, action.url))
			}
		}
	}
}

function* showFilterResult(action: any) {
	const state = store.getState().filter
	const { sort } = store.getState().commonProjects
	const { sort: sortUserProjectsList } = store.getState().userProjects

	const currentFilter =
		action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'user-projects'
			? state.userProjects
			: action.url === 'user-archive'
			? state.userArchive
			: state.userProjects

	yield put(appliedFilters({}))
	yield put(appliedFilters(currentFilter.showFilterState))
	const object = excludeData(currentFilter.showFilterState)

	const width = (currentFilter && currentFilter.sortState?.with) || []

	let params = {}
	if (action.url === 'common-projects') {
		params = {
			...object,
			limit: 5,
			with: [...width, 'IDs'],
			order: (sort.order ? '' : '-') + sort.sortName,
			...params,
		}
	} else if (action.url === 'user-projects' || action.url === 'user-archive') {
		params = { order: sortUserProjectsList.sortName, limit: 5, ...object }
	}
	if (state.showFilterResult && state.refreshList && state.toggleLoadingList) {
		yield put(state.toggleLoadingList(true))

		const res = yield call(state.showFilterResult, params)

		if (res?.projects) {
			const projects: ICommonProject[] = res.projects
			const count = Number(res.resultsCnt)
			const valuationOfferForecastSum: number = Number(res.valuationOfferForecastSum) / 10 ** 6
			const valuationPlanForecastSum: number = Number(res.valuationPlanForecastSum) / 10 ** 6
			const uncInfo: IUncInfo = res.uncInfo
			const IDs: string[] = res.IDs
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			if (uncInfo.voltageValues) {
				const newVoltageValues = Object.values(uncInfo.voltageValues)
				uncInfo.voltageValues = newVoltageValues
			} else {
				uncInfo.voltageValues = ['0.4', '35', '110', '220', '330', '500', '750']
			}

			if (action.url === 'favorites-common-projects') {
				yield put(state.refreshList(projects, false))
				yield put(state.toggleLoadingList(false))
			} else {
				yield put(setCommonProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(state.refreshList(projects, false))
				yield put(setAllowMoreProjects(true))
				yield put(setCommonProjectsCount(count))
				yield put(setCommonProjectsPaginationCount(0))
				yield put(setUncInfo(uncInfo))
				yield put(state.toggleLoadingList(false))
				yield put(showBtnCopyProjects2Calc(true))
				yield put(setFilterIdsCommonProjects(IDs))
			}
		} else if (res?.userProjects.length && res?.userProjects[0].archive) {
			yield put(setUserProjectsArchive(res.userProjects, res.totalInvestmentUncOfferSum, Number(res.userProjectsCnt)))
		} else if (res?.userProjects.length && !res?.userProjects[0].archive) {
			yield put(setUserProjects(res.userProjects, res.totalInvestmentUncOfferSum, Number(res.userProjectsCnt)))
			yield put(setFilterResultCount(res.userProjectsCnt, action.url))
			yield put(setAllowMoreUserProjects(true))
		} else {
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			if (res.uncInfo.voltageValues) {
				res.uncInfo.voltageValues = Object.values(res.uncInfo.voltageValues)
			} else {
				res.uncInfo.voltageValues = ['0.4', '35', '110', '220', '330', '500', '750']
			}

			yield put(state.refreshList(res, false))
		}
	}
}

function* handleChangeFilterSaga(action: any) {
	yield put(filtersRequest(action.url))
}

function* copyProjects2Calc(action: any) {
	const url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/copyProjects2Calc`
	const { commonProjects } = store.getState().filter
	const { sort } = store.getState().commonProjects

	let params = {
		order: (sort ? '' : '-') + sort.sortName,
	}
	const object = excludeData(commonProjects.showFilterState)
	const intermediateValue = {
		...object,
	}
	if (commonProjects.showFilterState || !!Object.keys(intermediateValue).length) {
		params = {
			...params,
			...intermediateValue,
		}
	}
	const paramsString = Object.entries(params)
		.filter(([key, value]) => value !== undefined)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string | number | boolean)}`)
		.join('&')

	const urlWithParams = `${url}?${paramsString}`

	try {
		const response = yield fetch(urlWithParams, {
			method: 'POST',
			headers: fetchHeaders(),
		})

		yield put(
			showNotify({
				type: 'success',
				message: `Выбранные ИПР успешно скопированы в проекты`,
			})
		)
		return response
	} catch (error) {
		console.log(error)
	}
}

export default function* watchFilter() {
	yield takeEvery(FilterActions.SHOW_FILTER_RESULT, showFilterResult)
	yield debounce(500, FilterActions.REFRESH_FILTER_STATE, handleChangeFilterSaga)
	yield takeLatest(FilterActions.FILTER_REQUEST, refreshFilterCountResult)
	yield takeLatest(FilterActions.COPY_PROJECTS_2_CALC, copyProjects2Calc)
}
