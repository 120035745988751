import { IUnc } from '@root-gipro/store/interfaces'

const minPrice = 0
const maxPrice = 90000000000

export const filteredUncCellCode = function (query: string, uncs: IUnc[]): IUnc[] {
	const localQuery = query !== null ? query.toLowerCase().trim() : ''

	return uncs.filter(unc => unc.uncCell?.code.toLowerCase().trim().includes(localQuery))
}

export const filteredUncTechnicalSolutions = function (query: string, uncs: IUnc[]): IUnc[] {
	const localQuery = query !== null ? query.toLowerCase().trim() : ''

	return uncs.filter(unc => unc.uncMain.name.toLowerCase().trim().includes(localQuery))
}

export const filteredUncCount = function (query: string, uncs: IUnc[]) {
	let [startValue = minPrice, endValue = maxPrice] = query !== null ? query.split('-') : [minPrice, maxPrice]

	startValue = Number(startValue.toString().trim())

	if (endValue === '') {
		endValue = maxPrice
	}

	return uncs.filter(unc => Number(unc.count) >= startValue && Number(unc.count) <= Number(endValue))
}

export const filteredUncUnit = function (query: string, uncs: IUnc[]) {
	const localQuery = query !== null ? query.toLowerCase().trim() : 'all'

	if (query === 'all' || localQuery === 'all') {
		return uncs
	} else {
		return uncs.filter(unc => unc.uncMain.unit.toLowerCase().trim().includes(localQuery))
	}
}

export const filteredUncParameters = function (query: string, uncs: IUnc[]) {
	const localQuery = query !== null ? query.toLowerCase().trim() : 'all'

	if (query === 'all' || localQuery === 'all') {
		return uncs
	} else {
		return uncs.filter(unc => unc.uncCell.parameter && unc.uncCell.parameter.toLowerCase().trim() === localQuery)
	}
}
export const filteredUncVoltage = function (query: string, uncs: IUnc[]) {
	const localQuery = query !== null ? query.toLowerCase().trim() : 'all'

	if (query === 'all' || localQuery === 'all') {
		return uncs
	} else {
		return uncs.filter(unc => unc.voltage && unc.voltage === localQuery)
	}
}

export const filteredUncCost = function (query: string, uncs: IUnc[]) {
	let [startValue = minPrice, endValue = maxPrice] = query !== null ? query.split('-') : [minPrice, maxPrice]
	startValue = Number(startValue.toString().trim())

	if (endValue === '') {
		endValue = maxPrice
	}

	return uncs.filter(unc => Number(unc.uncCell.cost) >= startValue && Number(unc.uncCell.cost) <= Number(endValue))
}

export const filteredUncRegionFactor = (query: string, uncs: IUnc[]): IUnc[] => {
	const localQuery = query ? query.toLowerCase().trim() : ''

	// Обрабатываем исходный массив, заменяя `null` в `regionFactor` на объект с `value: '1.00'` и отбираем id отфильтрованных объектов
	const filteredUncsIds = uncs
		.map(unc => ({
			...unc,
			regionFactor: unc.regionFactor || { value: '1.00' }, // Если regionFactor === null, заменяем на { value: '1.00' }
		}))
		.filter(unc => unc.regionFactor.value.toString().toLowerCase().trim().includes(localQuery))
		.map(unc => unc.id)

	return uncs.filter(unc => filteredUncsIds.includes(unc.id))
}

export const filteredTotalUncCost = function (query: string, uncs: IUnc[]) {
	let [startValue = minPrice, endValue = maxPrice] = query !== null ? query.split('-') : [minPrice, maxPrice]

	startValue = Number(startValue.toString().trim())

	if (endValue === '') {
		endValue = maxPrice
	}

	return uncs.filter(
		unc => Number(unc.totalCost / 1000) >= startValue && Number(unc.totalCost / 1000) <= Number(endValue)
	)
}
