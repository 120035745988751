import { Grid } from '@material-ui/core'
import style from '@root-gipro/modules/header/styles/HeaderNav.module.scss'
import '@root-gipro/modules/header/styles/HeaderNavSubmenu.scss'
import { Routes } from '@root-gipro/router'
import VerifiedIcon from '@root-gipro/shared/SVGComponents/VerifiedIcon'
import React, { memo, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const HeaderNav: React.FC = memo(() => {
	const [roleID, setRoleID] = useState(Number(window.localStorage.getItem('roleId')))
	const [showAdminPanel, setShowAdminPanel] = useState(false)

	setTimeout(() => {
		setRoleID(Number(window.localStorage.getItem('roleId')))
	}, 1000)

	useEffect(() => {
		if (roleID !== null) {
			setShowAdminPanel(roleID <= 10)
		}
	}, [roleID])

	return (
		<Grid>
			<NavLink
				title='Доступные действующие инвестиционные программы электросетевых компаний'
				to={Routes.COMMON_PROJECTS}
				activeClassName={style.active}
				className={style.navLink}
			>
				<span className={style.linkWrapper}>
					ИПР
					<span className={style.verifiedIcon}>
						<VerifiedIcon />
					</span>
				</span>
			</NavLink>

			<NavLink
				title='Рабочая среда редактирования версий инвестиционных программ, выполнения расчетов УНЦ'
				to={Routes.USER_PROJECTS}
				activeClassName={style.active}
				className={style.navLink}
			>
				Проекты
			</NavLink>
			<NavLink
				title='Раздел, в котором находятся не актуальные проекты, но имеющие ценность для возможного использования в перспективе'
				to={Routes.USER_ARCHIVE}
				activeClassName={style.active}
				className={style.navLink}
			>
				Архив
			</NavLink>
			{showAdminPanel ? (
				<NavLink
					title='Раздел, содержащий дополнительные функции по управлению ролями пользователей, версиями инвестиционных программ, настройки доступа к базам ИПР, загрузки форм, настройки точности расчетов'
					to={Routes.ADMIN}
					activeClassName={style.active}
					className={style.navLink}
				>
					Администрирование
				</NavLink>
			) : null}
		</Grid>
	)
})

export default HeaderNav
