import React, { ReactText, useEffect } from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { ListItemText, ListItem, List, Backdrop, CircularProgress } from '@material-ui/core/'
import { ICommonProject, IState } from '@root-gipro/store/interfaces'
import { IRgStatuses } from '@root-gipro/store/interfaces'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setIdAddedUserProject } from '@root-gipro/modules/commonProjects/store/actions'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	})
)

export interface OptionsProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	policesGipro: boolean
	policesRGCO: boolean
	rgStatusId: string | undefined
	copyHrefToClipboard: () => void
	downloadCommonProject(id: ReactText, code: string): void
	project: ICommonProject | null
	addToUserProject(projectId: string | number): void
	getRgStatusId: () => void
	rgStatuses: IRgStatuses[]
	toggler?: boolean
	togglerSubmenu?(): void
	idAddedUserProject: string | null
	downloadUncTechnicalSpecifications?(id: ReactText, code: string): void
}

const ParamsOptions: React.FC<OptionsProps> = ({
	anchorEl,
	setAnchorEl,
	policesGipro,
	policesRGCO,
	rgStatusId,
	copyHrefToClipboard,
	downloadCommonProject,
	project,
	addToUserProject,
	getRgStatusId,
	rgStatuses,
	toggler,
	togglerSubmenu,
	idAddedUserProject,
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const handleToUserProject = () => {
		if (project) {
			project && addToUserProject(project.id)
		}
	}
	const classes = useStyles()
	const { loadingCopy2Calc } = useSelector((state: IState) => state.commonProjects)

	useEffect(() => {
		if (idAddedUserProject) {
			history.push(`/user-projects/project/${idAddedUserProject}/parameters`)
			dispatch(setIdAddedUserProject(null))
		}
	}, [idAddedUserProject])

	return (
		<>
			{project && (
				<DropDown
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					style={{ borderRadius: 4 }}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<>
						<List className='drop-down__list' component='nav' aria-label='main mailbox folders'>
							<ListItem button className='drop-down-item ' onClick={handleToUserProject}>
								<span className='copy-icon icon-size ' />
								<ListItemText primary='Скопировать в проекты' />
							</ListItem>

							{policesRGCO && (
								<ListItem
									button
									className={`drop-down-item ${rgStatusId === '4' ? 'link-disabled' : ''}`}
									onClick={() => {
										if (rgStatusId && rgStatusId !== '4') {
											return window.open(`https://rg.unc-esk.ru/ispoldocums?find=${project.code}`)
										}
										if (!rgStatusId) {
											return getRgStatusId()
										}
									}}
								>
									<span className='query-icon icon-size ' />
									<ListItemText primary={rgStatusId ? rgStatuses[parseInt(rgStatusId) - 1].title : `Запросить ИД`} />
								</ListItem>
							)}
						</List>
					</>
				</DropDown>
			)}
			<Backdrop className={classes.backdrop} open={loadingCopy2Calc}>
				<CircularProgress color='primary' size={100} />
			</Backdrop>
		</>
	)
}

export default ParamsOptions
