import style from '@root-gipro/modules/AdminPanel/styles/adminPanel.module.scss'

import { createStyles, IconButton, makeStyles, TextField, Theme } from '@material-ui/core'
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded'
import { getAuthUserToken } from '@root-gipro/modules/AdminPanel/store/actions'
import Loader from '@root-gipro/shared/Loader/Loader'
import { IState } from '@root-gipro/store/interfaces'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminPanelCreateUser from './components/AdminCreateUser/AdminPanelCreateUser'
import AdminPanelTable from './components/AdminPanelTable'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: 8,
		},
		textField: {
			'& .MuiInputBase-root': {
				width: '300px',
			},
		},
	})
)

const AdminPanel: FC = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { users, usersLoading } = useSelector((state: IState) => state.adminPanel)

	const [searchText, setSearchText] = useState('')

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value)
	}

	const [open, setOpen] = React.useState(false)
	const handleOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		dispatch(getAuthUserToken())
	}, [])

	return (
		<div className='adminPanel-container'>
			<div className={style.adminPanel__header}>
				<div className={style.adminPanel__headerLeft}>
					<IconButton onClick={handleOpen} classes={classes}>
						<PersonAddRoundedIcon />
					</IconButton>
					<TextField
						className={classes.textField}
						variant='outlined'
						placeholder='ФИО'
						value={searchText}
						onChange={handleSearchChange}
					/>
				</div>
				<p>
					Пользователей: <span className={style.boldText}>{users?.length ?? 0}</span>
				</p>
			</div>

			{usersLoading ? <Loader /> : <AdminPanelTable users={users} searchText={searchText} />}
			<AdminPanelCreateUser open={open} handleClose={handleClose} />
		</div>
	)
}

export default AdminPanel
