import { Tab, Tooltip, Typography } from '@material-ui/core'
import style from '@root-gipro/shared/TabsComponents/TabsComponents.module.scss'
import cn from 'classnames'
import React, { FC } from 'react'
import { TabsGroup } from '../TabsComponents/CustomTabs'

interface ITabUncProjects {
	currentTab: string | null
}

const types = [
	{
		name: '2018',
		path: '',
	},
	{
		name: '2024',
		path: '',
	},
]

const TabUncProjects: FC<ITabUncProjects> = ({ currentTab }) => {
	return (
		<TabsGroup value={currentTab} className={style.checkPlateTabs}>
			{types &&
				currentTab &&
				types.map((type: any) => (
					<Tooltip
						key={type.name}
						title={
							type.name == 2018 ? (
								<Typography>
									Режим расчета по сборнику УНЦ, утверждённому в 2019 году. Базовыми являются расценки 2018 года
								</Typography>
							) : (
								<Typography>
									Режим расчета по сборнику УНЦ, утверждённому в 2024 году. Базовыми являются расценки 2023 года
								</Typography>
							)
						}
					>
						<span>
							<Tab
								className={cn({
									[style.active]: currentTab === type.name,
								})}
								disabled={true}
								disableRipple
								label={type.name == 2018 ? '2019' : type.name}
								value={type.name}
							/>
						</span>
					</Tooltip>
				))}
		</TabsGroup>
	)
}

export default TabUncProjects
