import style from '@root-gipro/modules/AdminPanel/styles/adminPanel.module.scss'

import { createStyles, IconButton, makeStyles, TextField } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Loader from '@root-gipro/shared/Loader/Loader'
import { IState } from '@root-gipro/store/interfaces'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyGroupsFetch, setCompanyState, setOpenModalCreateCompany } from '../../store/actions'
import { ROLE_OWNER } from '../../store/helpers'
import AdminCompaniesTable from './components/AdminCompaniesTable'
import AdminCompanyDetails from './components/AdminCompanyDetails/AdminCompanyDetails'
const useStyles = makeStyles(() =>
	createStyles({
		root: {
			borderRadius: 8,
		},
		textField: {
			'& .MuiInputBase-root': {
				width: '300px',
			},
		},
	})
)

const AdminPanelCompany = () => {
	const classes = useStyles()
	const { openModalCompany, companies } = useSelector((state: IState) => state.adminPanel)
	const dispatch = useDispatch()
	const roleId = localStorage.getItem('roleId')

	const [searchText, setSearchText] = useState('')

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value)
	}

	useEffect(() => {
		dispatch(getCompanyGroupsFetch())
	}, [])

	const handleClose = () => {
		dispatch(setOpenModalCreateCompany({ open: false }))
	}

	const handleBtn = () => {
		dispatch(setOpenModalCreateCompany({ open: true, mode: 'createCompany' }))
		dispatch(setCompanyState(null))
	}

	return (
		<div className='adminPanel-container'>
			<div className={style.adminPanel__header}>
				<div className={style.adminPanel__headerLeft}>
					{roleId && Number(roleId) <= ROLE_OWNER ? (
						<IconButton onClick={handleBtn} classes={classes}>
							<Add />
						</IconButton>
					) : null}
					<TextField
						className={classes.textField}
						variant='outlined'
						placeholder='Наименование/ИНН'
						value={searchText}
						onChange={handleSearchChange}
					/>
				</div>
				<p>
					Компаний: <span className={style.boldText}>{companies?.length ? companies?.length : 0}</span>
				</p>
			</div>
			{companies?.length ? <AdminCompaniesTable companies={companies} searchText={searchText} /> : <Loader />}
			<AdminCompanyDetails open={openModalCompany.open} handleClose={handleClose} action={openModalCompany.mode} />
		</div>
	)
}

export default AdminPanelCompany
