import { Tab, Tooltip, Typography } from '@material-ui/core'
import style from '@root-gipro/shared/TabsComponents/TabsComponents.module.scss'
import { IState } from '@root-gipro/store/interfaces'
import cn from 'classnames'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TabsGroup } from '../TabsComponents/CustomTabs'

interface IVersion {
	userProjectId: number
	name: string
	is_actual: boolean
	unc_sort: string
}

const types = [
	{
		name: '2018',
		path: '',
	},
	{
		name: '2024',
		path: '',
	},
]

const TabUncProject = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const { selectedProject } = useSelector((state: IState) => state.commonProjects)

	const [selectedTab, setSelectedTab] = useState<string | null>(null)

	return (
		<>
			<TabsGroup value={selectedProject?.unc_sort} disabled={true} className={style.checkPlateTabs}>
				{types &&
					types.map((type: any) => (
						<Tooltip
							key={type.name}
							title={
								type.name == 2018 ? (
									<Typography>
										Режим расчета по сборнику УНЦ, утверждённому в 2019 году. Базовыми являются расценки 2018 года
									</Typography>
								) : (
									<Typography>
										Режим расчета по сборнику УНЦ, утверждённому в 2024 году. Базовыми являются расценки 2023 года
									</Typography>
								)
							}
						>
							{selectedProject?.unc_sort == type.name ? (
								<span>
									<Tab
										className={cn({
											[style.active]: selectedProject?.unc_sort === type.name,
										})}
										disableRipple
										label={type.name == 2018 ? '2019' : type.name}
										value={type.name}
									/>
								</span>
							) : (
								<Tab
									className={cn({
										[style.active]: selectedProject?.unc_sort === type.name,
									})}
									disableRipple
									label={type.name == 2018 ? '2019' : type.name}
									value={type.name}
								/>
							)}
						</Tooltip>
					))}
			</TabsGroup>
		</>
	)
}

export default TabUncProject
