import {
	IFilial,
	IGroup,
	ICompany,
	IRegion,
	IRgStatuses,
	IUncMain,
	IUncThDescriptions,
	IUncThs,
} from '@root-gipro/store/interfaces'

const getId = (parameter: string[], collection: any[], text: string) => {
	if (text === 'version_name') {
		const foundItem = collection?.find(item => parameter?.includes(item[text]) && item[text] === parameter)

		if (foundItem) {
			return Number(foundItem.id)
		}
	} else if (text !== 'version_name' && parameter) {
		return collection
			.filter(item => parameter.includes(item[text]))
			.map(({ id }) => {
				return Number(id)
			})
	}
}

const getCode = (parameter: string[], collection: any[], text: string) =>
	collection.filter(item => parameter.includes(item[text])).map(({ code }) => Number(code))

const getName = (parameter: string[], collection: any[], text: string, field: string) =>
	collection.filter(item => parameter.includes(item[text])).map(item => item[field])

const toNeedConvertPrice = [
	'project.valuationOfferForecast.max',
	'project.valuationOfferForecast.min',
	'project.balanceOffer.min',
	'project.balanceOffer.max',

	'valuationUncOfferForecast.min',
	'valuationUncOfferForecast.max',
	'valuationOfferForecast.max',
	'valuationOfferForecast.min',
	'balanceOffer.min',
	'balanceOffer.max',

	'estimatedOfferCurrent.max',
	'estimatedOfferCurrent.min',

	'project.estimatedOfferCurrent.max',
	'project.estimatedOfferCurrent.min',
	'project.estimatedOfferYM.max',
	'project.estimatedOfferYM.min',
	'plan.maximumPrice.min',
	'plan.maximumPrice.max',
	'notice.initialSum.min',
	'notice.initialSum.max',
] as string[]

const needGetId: string[] = [
	'filialId',
	'companyId',
	'ipr_id',
	'region_code',
	'plan.regionId',
	'regionId',
	'notice.regionId',
	'groupId',
	'company_id',
	'uncCell.uncThId',
	'uncCell.uncTextParameterId',
	'requestId',
	'uncCell.uncMainId',
	'rgStatusId',
	'project.rgStatusId',
	'project.uncCell.uncThId',
	'project.uncCell.uncMainId',
]

const needGetIdMap = (
	filial: IFilial[],
	regions: IRegion[],
	groups: IGroup[],
	company: ICompany[],
	uncThs: IUncThs[],
	uncThDescriptions: IUncThDescriptions[],
	rgStatusesUp: any[],
	uncMains: IUncMain[],
	versionIpr?: any[]
) =>
	new Map<string, any>([
		['filialId', (value: any) => getId(value, filial, 'name')],
		['companyId', (value: any) => getId(value, company, 'name')],
		['region_code', (value: any) => getCode(value, regions, 'name')],
		['notice.regionId', (value: any) => getId(value, regions, 'name')],
		['plan.regionId', (value: any) => getId(value, regions, 'name')],
		['regionId', (value: any) => getId(value, regions, 'name')],
		['groupId', (value: any) => getId(value, groups, 'name')],
		['company_id', (value: any) => getId(value, company, 'name')],
		['uncCell.uncThId', (value: any) => getId(value, uncThs, 'name')],
		['uncCell.uncTextParameterId', (value: any) => getId(value, uncThDescriptions, 'title')],
		['uncCell.uncMainId', (value: any) => getId(value, uncMains, 'code')],
		['rgStatusId', (value: any) => getId(value, rgStatusesUp, 'title')],
		['project.rgStatusId', (value: any) => getId(value, rgStatusesUp, 'title')],
		['project.uncCell.uncThId', (value: any) => getId(value, uncThs, 'name')],
		['project.uncCell.uncMainId', (value: any) => getId(value, uncMains, 'code')],
		//@ts-ignore
		['ipr_id', (value: any) => getId(value, versionIpr, 'version_name')],
	])

const needGetNameMap = (
	filial: IFilial[],
	company: ICompany[],
	regions: IRegion[],
	groups: IGroup[],
	uncThs: IUncThs[],
	uncThDescriptions: IUncThDescriptions[],
	rgStatusesUp: any[],
	uncMains: IUncMain[]
) =>
	new Map<string, any>([
		['filialId', (value: any) => getName(value, filial, 'id', 'name')],
		['companyId', (value: any) => getName(value, company, 'id', 'name')],
		['notice.regionId', (value: any) => getName(value, regions, 'id', 'name')],
		['plan.regionId', (value: any) => getName(value, regions, 'id', 'name')],
		['regionId', (value: any) => getName(value, regions, 'id', 'name')],
		['notice.groupId', (value: any) => getName(value, groups, 'id', 'name')],
		['plan.groupId', (value: any) => getName(value, groups, 'id', 'name')],
		['groupId', (value: any) => getName(value, groups, 'id', 'name')],
		['uncCell.uncThId', (value: any) => getName(value, uncThs, 'id', 'name')],
		['uncCell.uncTextParameterId', (value: any) => getName(value, uncThDescriptions, 'id', 'title')],
		['uncCell.uncMainId', (value: any) => getName(value, uncMains, 'id', 'code')],
		['rgStatusId', (value: any) => getName(value, rgStatusesUp, 'id', 'title')],
		['project.rgStatusId', (value: any) => getName(value, rgStatusesUp, 'id', 'title')],
		['project.uncCell.uncThId', (value: any) => getName(value, uncThs, 'id', 'name')],
		['project.uncCell.uncMainId', (value: any) => getName(value, uncMains, 'id', 'code')],
	])

const needToInt: string[] = ['isElectronic', 'project.valuationUncOfferForecast', 'project.estimatedOfferYM']
const needToEmpty: string[] = ['valuationUncOfferForecast', 'estimatedOfferYM']
export const convertFilterValue = (
	filial: IFilial[],
	regions: IRegion[],
	groups: IGroup[],
	company: ICompany[],
	uncThs: IUncThs[],
	uncThDescriptions: IUncThDescriptions[],
	rgStatusesUp: IRgStatuses[],
	uncMains: IUncMain[],
	versionIpr?: any[]
) => (fieldName: string, value: any): { fieldName: string; value: any } => {
	const needIdMap = needGetIdMap(
		filial,
		regions,
		groups,
		company,
		uncThs,
		uncThDescriptions,
		rgStatusesUp,
		uncMains,
		versionIpr
	)

	if (toNeedConvertPrice.includes(fieldName)) {
		if (value) {
			return { fieldName, value: parseFloat(value.replace(/[^0-9.]/g, '')) * 10 ** 6 }
		}
	}

	if (needGetId.includes(fieldName)) {
		const convert = needIdMap.get(fieldName)

		if (convert) {
			return { fieldName, value: convert(value) }
		}
	}

	if (needToEmpty.includes(fieldName)) {
		return { fieldName, value: '' }
	}

	if (needToInt.includes(fieldName)) {
		return { fieldName, value: Number(value) }
	}

	return { fieldName, value }
}

export const revertFilterValue = (
	filial: IFilial[],
	company: ICompany[],
	regions: IRegion[],
	groups: IGroup[],
	uncThs: IUncThs[],
	uncThDescriptions: IUncThDescriptions[],
	rgStatusesUp: IRgStatuses[],
	uncMains: IUncMain[],
	filterState: any
): any => {
	let resultFilterState = {}
	const needNameMap = needGetNameMap(
		filial,
		company,
		regions,
		groups,
		uncThs,
		uncThDescriptions,
		rgStatusesUp,
		uncMains
	)
	Object.keys(filterState).forEach(fieldName => {
		if (toNeedConvertPrice.includes(fieldName)) {
			resultFilterState = {
				...resultFilterState,
				[fieldName]: Math.sqrt(filterState[fieldName]),
			}
			return
		}
		if (needGetId.includes(fieldName)) {
			const convert = needNameMap.get(fieldName)
			if (convert) {
				resultFilterState = {
					...resultFilterState,
					[fieldName]: convert(filterState[fieldName]),
				}
				return
			}
		}
		if (needToInt.includes(fieldName)) {
			resultFilterState = {
				...resultFilterState,
				[fieldName]: Boolean(filterState[fieldName]),
			}
			return
		}
		resultFilterState = {
			...resultFilterState,
			[fieldName]: filterState[fieldName],
		}
	})
	return resultFilterState
}
