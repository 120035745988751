import React, { FC } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import styled from 'styled-components'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'

interface IDataConfig {
	anchorEl: (EventTarget & HTMLElement) | null | undefined
	setAnchorEl(event: HTMLElement): void
	handlerOpen?: (e: any) => void
	handlerClose: () => void
	notes?: string
	coeff?: any[]
	transitConfig?: boolean
}

const ContainerModal = styled.div``

const ContainerNotes = styled.div`
	margin-bottom: 20px;
`

const PositionButton = styled.div`
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100%;
	top: 10px;
`
const ContainerButton = styled.div`
	position: relative;
	height: 20px;
`
const Title = styled.div`
	font-size: 17px;
    font-weight: 500;
    margin-bottom: 15px;
`

const TransitionConfigurator: FC<IDataConfig> = ({
	anchorEl,
	setAnchorEl,
	notes,
	coeff,
	handlerOpen,
	handlerClose,
	transitConfig,
}) => {
	const hasElement = (element: number) => coeff?.includes(element)
	return (
		<Modal setAnchorEl={setAnchorEl} anchorEl={anchorEl} widthWindow='400px' >
			<Title>Допустимые усложняющие коэффициенты</Title>
			<ContainerModal>
				<div>
					<ContainerNotes>{notes}</ContainerNotes>
					<div>Кф1 - {hasElement(1) ? 'да' : 'нет'}</div>
					<div>Кф2 - {hasElement(2) ? 'да' : 'нет'}</div>
					<div>Кф3 - {hasElement(3) ? 'да' : 'нет'}</div>
				</div>
				<ContainerButton>
					<PositionButton>
						<SecondaryBtn onClick={handlerClose}>Закрыть</SecondaryBtn>
						{transitConfig ? (
							<PrimaryBtn data-popup={'comment'} onClick={handlerOpen}>
								Перейти в конфигуратор
							</PrimaryBtn>
						) : null}
					</PositionButton>
				</ContainerButton>
			</ContainerModal>
		</Modal>
	)
}

export default TransitionConfigurator
