import { Item } from '@root-gipro/modules/userProjects/components/UserProjects/Item'
import Loader from '@root-gipro/shared/Loader/Loader'
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler'
import { IUserProject } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appendUserProjects } from '../../store/actions'

const List: React.FC = () => {
	const dispatch = useDispatch()
	// eslint-disable-next-line prefer-const
	let { projectList, projectFilter, paginationCounter, loading, allowMoreUserProjects } = useSelector(
		(state: IState) => state.userProjects
	)
	const listRef = useRef<HTMLDivElement>(null)

	const checkAndLoadMore = () => {
		if (!listRef.current || !projectList.length || loading) return

		const { scrollHeight } = listRef.current
		const height = listRef.current.getBoundingClientRect().height

		if (!loading && allowMoreUserProjects && scrollHeight <= height) {
			dispatch(appendUserProjects(paginationCounter + 1))
		}
	}

	useEffect(() => {
		const handleResize = () => {
			checkAndLoadMore()
		}

		window.addEventListener('resize', handleResize)
		if (projectList.length) checkAndLoadMore() // Проверка при монтировании

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [loading, allowMoreUserProjects, paginationCounter])

	const onScroll = (e: any) => {
		const scrollTop = e.target.scrollTop
		const scrollHeight = e.target.scrollHeight
		const height = e.target.getBoundingClientRect().height

		// Проверка на прокрутку до конца и наличие проектов для подгрузки
		if (
			!loading &&
			allowMoreUserProjects &&
			projectList.length &&
			scrollTop &&
			scrollHeight - scrollTop <= height + 10
		) {
			dispatch(appendUserProjects(paginationCounter + 1)) // Увеличиваем и используем новый счетчик
		}
	}

	return (
		<>
			<ScrollHandler />
			<div className='user-projects__list' ref={listRef} onScroll={onScroll}>
				{projectList &&
					projectList
						.filter((project: IUserProject) => project.code.match(projectFilter) || project.name.match(projectFilter))
						.map(item => <Item key={item.id} data={item} />)}
				<div>{loading && <Loader />}</div>
			</div>
		</>
	)
}

export default List
