import { IPublic } from '@root-gipro/store/interfaces/state'
import produce, { Draft } from 'immer'
import { PublicType, PublicTypes } from '../actions'

const initialState: IPublic = {
	policesGipro: false,
	policesRGCO: false,
	filial: [],
	groups: [],
	company: [],
	regions: [],
	uncMains: [],
	publicationDates: [],
	purchasePublishDates: [],
	yearBegin: '',
	yearEndOffer: '',
	uncSections: [],
	uncThs: [],
	uncThDescriptions: [],
	rgStatuses: [],
	purchasesMethods: [],
	purchasesStages: [],
	purchasesOkpd: [],
	purchasesOkved: [],
	uncTechnicalSpecifications: {
		name: {},
		paramName: {},
		param: {},
	},
	failure: false,
	userSettings: {},
}
const publicFn = (draft: Draft<IPublic>, action: PublicTypes) => {
	switch (action.type) {
		case PublicType.SET_PUBLIC_INFO:
			draft.policesGipro = action.policesGipro
			draft.policesRGCO = action.policesRGCO
			draft.filial = action.filial
			draft.groups = action.groups
			draft.company = action.company
			draft.regions = action.regions
			draft.publicationDates = action.publicationDates
			draft.purchasePublishDates = action.purchasePublishDates
			draft.uncThDescriptions = action.uncThDescriptions
			draft.rgStatuses = action.rgStatuses
			draft.userSettings = action.userSettings
			break

		case PublicType.SET_PUBLIC_USER_PROJECT_INFO:
			draft.uncMains = action.uncMains
			draft.uncSections = action.uncSections
			draft.uncThs = action.uncThs
			break

		case PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS:
			draft.uncTechnicalSpecifications = action.uncTechnicalSpecifications
			break
		case PublicType.SET_PURCHASES_METHODS:
			draft.purchasesMethods = action.purchasesMethods
			break
		case PublicType.SET_PURCHASES_STAGES:
			draft.purchasesStages = action.purchasesStages
			break
		case PublicType.SET_PURCHASES_OKPD:
			draft.purchasesOkpd = action.purchasesOkpd
			break
		case PublicType.SET_PURCHASES_OKVED:
			draft.purchasesOkved = action.purchasesOkved
			break
		case PublicType.SET_FETCH_FAILURE:
			draft.failure = action.failure
			break
		case PublicType.UPDATE_COMPANY_LIST:
			draft.company = action.company
			break
		default:
			return draft
	}
}
export const publicReducer = produce(publicFn, initialState)
